<template>
  <div class="audienceWrap">
    <el-form
      label-width="110px"
      :model="formLabelAlign"
      :rules="rules"
      ref="ruleForm"
    >
      <el-form-item label="像素：" prop="pixelId">
        <el-select v-model="formLabelAlign.pixelId" placeholder="请选择">
          <el-option
            v-for="item in pixelList"
            :key="item.pixel_id"
            :label="item.name"
            :value="item.pixel_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="留存率：" prop="days">
        <el-input
          v-model.number="formLabelAlign.days"
          style="width: 217px"
          placeholder="请输入留存率"
          type="number"
          step="1"
        >
          <template slot="append">天</template>
        </el-input>
      </el-form-item>
      <el-form-item label="品类：" prop="catId">
        <el-select
          v-model="formLabelAlign.catId"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in cateList"
            :key="item.id"
            :label="item.full_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品ID：" prop="productIds">
        <el-input
          v-model="formLabelAlign.productIds"
          type="textarea"
          placeholder="多个逗号隔开，或者换行隔开"
          :rows="5"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="网址同时包含关键词："
        class="labelHeight"
        prop="andContainKeywords"
      >
        <div class="keyword">
          <el-tag
            :key="tag"
            v-for="tag in dynamicTags"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
          >
            {{ tag }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
            >+ 关键词</el-button
          >
        </div>
      </el-form-item>
      <el-form-item label="按：" prop="statType">
        <!-- <span class="text"></span> -->
        <el-select v-model="formLabelAlign.statType" placeholder="请选择">
          <el-option
            v-for="item in eventList"
            :key="item.key"
            :label="item.name"
            :value="item.key"
          >
          </el-option>
        </el-select>
        <span class="text">top</span>
        <el-select v-model="formLabelAlign.limit" placeholder="请选择">
          <el-option
            v-for="item in topNum"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <span class="text">商品</span>
      </el-form-item>
    </el-form>
    <div>
      <div class="creatWrap">
        <el-checkbox v-model="checked">创建类似受众</el-checkbox>
      </div>
    </div>
    <div v-if="checked">
      <el-form
        label-width="110px"
        :model="similarForm"
        ref="similarForm"
        :rules="rules1"
      >
        <el-form-item label="地区：" prop="area">
            <el-select v-model="similarForm.area"
                        clearable  
                        filterable
                        placeholder="选择受众所在地区">
                        <el-option
                            v-for="(item,index) in data_obj.TargetingCountry"
                            :key="index"
                            :label="item.name"
                            :value="item.key">
                        </el-option>
                    </el-select>
        </el-form-item>
        <el-form-item label="类似受众数量：">
          <el-select v-model="similarForm.num" placeholder="请选择" disabled>
            <el-option
              v-for="item in numbers"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-slider
            v-model="similarForm.scale"
            range
            :marks="marks"
            show-stops
            :max="10"
          >
          </el-slider>
          <p class="sliderTip">
            你可以选择指定地区总人口的 1% 到 10% 作为广告受众规模。1%
            的类似受众由与类似受众来源最相似的用户组成。提高百分比会创建规模更大、更广泛的受众。
          </p>
        </el-form-item>
        <el-form-item label="受众名称：" prop="name">
          <el-input v-model="similarForm.name"></el-input>
        </el-form-item>
        <el-form-item label="说明：">
          <el-input v-model="similarForm.description"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="cancle">取 消</el-button>
      <el-button type="primary" size="small" @click="creat">创 建</el-button>
    </div>
  </div>
</template>

<script>
import { accountPixel } from "@/api/creatAd.js";
import * as API from "@/api/customAudience.js";
let cateList = require("./cate.json");
export default {
    props:{
        data_obj:{
            type:Object,
            default:()=>{}
        },
    },
  data() {
    var checkDays = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("留存率不能为空"));
      } else {
        // console.log(value);
        if (value / 1 < 1 || value / 1 > 180) {
          return callback(new Error("必须1-180之间"));
        } else {
          if((value+'').indexOf('.')>=0){
            return callback(new Error("留存率不能是小数哦"));
          }
          callback();
        }
      }
    };
    var checkKeywords = (rule, value, callback) => {
      if (!this.dynamicTags.length) {
        return callback(new Error("请输入关键词"));
      } else {
        callback();
      }
    };
    return {
      formLabelAlign: {
        limit: 500,
        days: 30,
        statType:'purchase'
      },
      similarForm: {
        name: "",
        num: 1,
        scale: [0, 1],
      },
      checked: false,
      options: [],
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      marks: {
        0: "0%",
        1: "1%",
        2: "2%",
        3: "3%",
        4: "4%",
        5: "5%",
        6: "6%",
        7: "7%",
        8: "8%",
        9: "9%",
        10: {
          style: {
            width: "30px",
          },
          label: "10%",
        },
      },
      eventList: [
        { name: "加购", key: "add_cart" },
        { name: "添加支付", key: "add_pay_info" },
        { name: "结算", key: "checkout" },
        { name: "点击", key: "clicks" },
        { name: "展示", key: "impressions" },
        { name: "购买", key: "purchase" },
        { name: "触及", key: "reach" },
        { name: "浏览", key: "landing_page_view" },
      ],
      topNum: [{ id: 500, name: 500 }],
      pixelList: [],
      cateList: cateList.list.filter((v) => v.level == 1 || v.level == 2),
      numbers: [{ value: 1, label: 1 }],
      rules: {
        pixelId: [{ required: true, message: "请选择像素", trigger: "change" }],
        days: [{ required: true, validator: checkDays, trigger: "blur" }],
        catId: [{ required: true, message: "请选择品类", trigger: "change" }],
        productIds: [
          { required: false, message: "请填写产品ID", trigger: "blur" },
        ],
        // andContainKeywords: [
        //   { required: false, validator: checkKeywords, trigger: "blur" },
        // ],
        limit: [{ required: true, message: "请选择数量", trigger: "chang" }],
        statType: [
          { required: true, message: "请选择事件类型", trigger: "chang" },
        ],
      },
      rules1: {
        name: [{ required: true, message: "请填写受众名称", trigger: "blur" }],
        area: [{ required: true, message: "请选择受众地区", trigger: "change" }],
      },
    };
  },
  computed: {
    accountId() {
      return this.$store.getters.accountId;
    },
  },
  methods: {
    getPixels(flag) {
      this.$showLoading();
      accountPixel(
        { accountId: this.accountId, force: flag ? true : false },
        this
      ).then((res) => {
        this.$hideLoading();
        // console.log(res);
        this.pixelList = res.data;
      });
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    saveCateAudiece() {
      let params = {
        ...this.formLabelAlign,
        productIds:this.formLabelAlign.productIds?(this.formLabelAlign.productIds.indexOf('\n')>=0?this.formLabelAlign.productIds.split('\n'):this.formLabelAlign.productIds.split(',')):[],
        andContainKeywords: this.dynamicTags,
        accountId: this.accountId,
        catName: this.cateList.find((v) => v.id == this.formLabelAlign.catId)
          .name,
      };
      this.$showLoading()
      API.createSaleAudience(params).then((res) => {
        this.$hideLoading()
        if (res.code == 0) {
          if (this.checked) {
            this.creatSmilerAudience(res.data.audienceId);
          }else{
            this.$emit('clear')
            this.$emit('back')
          }
        }
      });
    },
    creatSmilerAudience(id) {
      let params = {
        accountId: this.accountId,
        name: this.similarForm.name,
        description: this.similarForm.description,
        subtype: "LOOKALIKE",
        origin_audience_id: id,
        lookalikeSpec:{
            starting_ratio:this.scale[0]/100,
            ratio:(this.scale[1]-this.scale[0])/100,
            type:'similarity',
            country:this.similarForm.area
        }
      };
      this.$showLoading()
      API.createLookalikeAudience(params).then((res) => {
          this.$hideLoading();
          if(res.code == 0){
            this.$emit('clear')
            this.$emit('back')
          }
      });
    },
    cancle() {
      this.$emit("clear");
    },
    creat() {
      if (this.checked) {
        this.$refs.ruleForm.validate((valid) => {
          this.$refs.similarForm.validate((valid1) => {
            if (valid && valid1) {
              this.saveCateAudiece();
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        });
      } else {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.saveCateAudiece();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }

    //   this.saveCateAudiece();
      // this.$emit('clear')
      // this.$emit('back')
    },
  },
  mounted() {
    this.getPixels();
  },
};
</script>

<style lang="scss">
.audienceWrap {
  max-height: 660px;
  overflow-y: auto;
  padding-right: 50px;
  .dialog-footer {
    text-align: right;
    padding-top: 15px;
  }
  .creatWrap {
    padding: 20px 0;
    padding-left: 110px;
  }
  .sliderTip {
    line-height: 24px;
    margin-top: 30px;
    color: #999;
    font-size: 12px;
  }
  .el-form-item.labelHeight {
    .el-form-item__label {
      line-height: 24px;
    }
    .keyword {
      border: 1px solid #dcdfe6;
      height: 100px;
      padding: 5px;
      .el-tag {
        margin: 5px;
      }
      .el-input {
        width: 300px;
      }
    }
  }
  .text {
    margin: 0 10px;
  }
}
</style>
// 所有的字典项数据

/**
 * 所有的设备类型   9种
 * @param {*} 
 */
const device_type = [
    {operator: 'eq', field: 'device_type', value: 'mobile_android_phone'},
    {operator: 'eq', field: 'device_type', value: 'mobile_android_tablet'},
    {operator: 'eq', field: 'device_type', value: 'mobile_ipad'},
    {operator: 'eq', field: 'device_type', value: 'mobile_iphone'},
    {operator: 'eq', field: 'device_type', value: 'mobile_ipod'},
    {operator: 'eq', field: 'device_type', value: 'mobile_phone'},
    {operator: 'eq', field: 'device_type', value: 'mobile_tablet'},
    {operator: 'eq', field: 'device_type', value: 'mobile_windows_phone'},
    {field: "device_type", operator: "eq", value: "desktop"},
]
/**
 * method 频次  
 * "absolute", 表示添加在指定范围内记录事件的人员，或者"percentile", 表示从指定百分比范围添加人员。如果选择percentile，则运算符应该只有in_rangeand not_in_range。
 * @param {*} 
 */
 const device_type_method = [{name:"频次",value:"absolute"},]
/**
 * 所有的设备分类  5种
 *  1.	所有：相当于清空  啥都没有
    2.	所有移动设备：
    0: {field: "device_type", operator: "eq", value: "mobile_android_phone"}
    1: {field: "device_type", operator: "eq", value: "mobile_android_tablet"}
    2: {field: "device_type", operator: "eq", value: "mobile_ipad"}
    3: {field: "device_type", operator: "eq", value: "mobile_iphone"}
    4: {field: "device_type", operator: "eq", value: "mobile_ipod"}
    5: {field: "device_type", operator: "eq", value: "mobile_phone"}
    6: {field: "device_type", operator: "eq", value: "mobile_tablet"}
    7: {field: "device_type", operator: "eq", value: "mobile_windows_phone"}
    3.	ios
    0: {field: "device_type", operator: "eq", value: "mobile_ipad"}
    1: {field: "device_type", operator: "eq", value: "mobile_iphone"}
    2: {field: "device_type", operator: "eq", value: "mobile_ipod"}
    4.	android
    0: {field: "device_type", operator: "eq", value: "mobile_android_phone"}
    1: {field: "device_type", operator: "eq", value: "mobile_android_tablet"}
    5.	桌面设备
    0: {field: "device_type", operator: "eq", value: "desktop"}
 * @param {*} 
 */
const device_type_dict = [
    {name:"所有",value:"all",code:[]},
    {name:"所有移动设备",value:"mobile",code:[{operator: 'eq', field: 'device_type', value: 'mobile_android_phone'},
    {operator: 'eq', field: 'device_type', value: 'mobile_android_tablet'},
    {operator: 'eq', field: 'device_type', value: 'mobile_ipad'},
    {operator: 'eq', field: 'device_type', value: 'mobile_iphone'},
    {operator: 'eq', field: 'device_type', value: 'mobile_ipod'},
    {operator: 'eq', field: 'device_type', value: 'mobile_phone'},
    {operator: 'eq', field: 'device_type', value: 'mobile_tablet'},
    {operator: 'eq', field: 'device_type', value: 'mobile_windows_phone'}]},
    {name:"IOS",value:"ios",code:[{field: "device_type", operator: "eq", value: "mobile_ipad"},
    {field: "device_type", operator: "eq", value: "mobile_iphone"},
    {field: "device_type", operator: "eq", value: "mobile_ipod"},
    ]},
    {name:"Android",value:"android",code:[{field: "device_type", operator: "eq", value: "mobile_android_phone"},
    {field: "device_type", operator: "eq", value: "mobile_android_tablet"},
    ]},
    {name:"桌面设备",value:"desktop",code:[{field: "device_type", operator: "eq", value: "desktop"}]},
]
/**
 * 运算符
 * @param {*} 
 */
 const device_operator = [
    {operator: '>', name: '大于'},
    {operator: '>=', name: '不小于'},
    {operator: '=', name: '等于'},
    {operator: '!=', name: '不等于'},
    {operator: '<=', name: '不大于'},
    {operator: '<', name: '小于'},

]

export {device_type,device_operator,device_type_dict,device_type_method}